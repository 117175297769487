<template>
  <div class="app-container">
    <div style="width:76%;float:left;margin-bottom:10px">
      <el-input placeholder="请输入科目编码或名称" v-model="listQuery.name" style="width: 200px;" size="small" />
      <el-button size="small" type="primary" @click="getList()"  >
        <el-icon><Search /></el-icon><span  > 搜索</span>
      </el-button>
      <el-button plain size="small" type="primary" @click="getList(item.value)" v-for="(item,index) in types" :key="index" >{{item.name}}</el-button>
    </div>
    <div style="width:20%;text-align:right;float:right">
      <qzf-video vid="4467be537471dbc14d63f148915094fb_4"></qzf-video>

      <!-- <el-button size="small" type="primary" plain @click="plDelete">批量删除</el-button> -->
      <el-dropdown size="small" style="margin-left:10px;margin-right:10px" :hide-on-click="false">
      <el-button size="small" type="primary">
          更多<el-icon class="el-icon--right"><arrow-down /></el-icon>
        </el-button>
        <template #dropdown>
          <el-dropdown-menu style="text-align:center">
            <el-dropdown-item command="c">
              <span @click="importSubject">导入</span>
            </el-dropdown-item>
            <el-dropdown-item command="a">
              <span @click="plDelete">批量删除</span>
            </el-dropdown-item>
            <el-dropdown-item command="b">
              <span @click="comAccounts">补全科目</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>

    <el-table stripe :data="list" :height="contentStyleObj" style="width: 100%" row-key="id" border lazy v-loading="loading"  
    element-loading-text="加载中" 
    :tree-props="{children: 'children', hasChildren: 'hasChildren'}" size="small" 
    @selection-change="handleSelectionChange"
    ref="tableDate">
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column align="center" type="selection" width="55"> </el-table-column>
      <el-table-column align="left" prop="code" label="科目编码" min-width="120"> </el-table-column>
      <el-table-column align="left" prop="name" label="科目名称" min-width="120"> </el-table-column>
      <el-table-column align="center" prop="type" label="余额方向" width="80">
        <template #default="scope">
          <span>{{ typeFilter(scope.row.type) }}</span>  
        </template>
      </el-table-column>
      <el-table-column align="center" prop="fzhsName" label="辅助核算" min-width="80"> </el-table-column>
      <el-table-column align="center" prop="wb" label="外币" min-width="80"> </el-table-column>
      <el-table-column align="center" label="操作" width="420">
        <template #default="scope">
          <qzf-button size="small" text @success="wbBinding(scope.row)">绑定外币</qzf-button>
          <qzf-button size="small" text @success="binding(scope.row)">绑定辅助核算</qzf-button>
          <qzf-button size="small" text @success="add(scope.row)">新增</qzf-button>
          <qzf-button size="small" text @success="edit(scope.row)">编辑</qzf-button>
          <qzf-button size="small" text @success="deleteRow(scope.row)">删除</qzf-button>

          <!-- <el-tooltip content="绑定外币" placement="top" v-if="this.$route.path != '/bookkeeps/hisSubject'">
            <el-icon @click="wbBinding(scope.row)"><Money /></el-icon>
          </el-tooltip>
          <el-tooltip content="绑定辅助核算" placement="top" v-if="code.indexOf(scope.row.code) == -1 && this.$route.path != '/bookkeeps/hisSubject'">
            <i class="iconfont icon-fuzhuhesuan" @click="binding(scope.row)"></i>
          </el-tooltip>
          <el-tooltip content="新增" placement="top">
            <i class="iconfont icon-jiahao" @click="add(scope.row)"></i>
          </el-tooltip>
          <el-tooltip content="编辑" placement="top">
            <i class="iconfont icon-bianji" @click="edit(scope.row)"></i>
          </el-tooltip>
          <el-tooltip content="删除" placement="top">
            <el-popconfirm title="是否确认删除" confirm-button-text="是" icon="InfoFilled" icon-color="red" cancel-button-text="否" @confirm="deleteRow(scope.row)" @cancel="cancelEvent">
              <template #reference>
                  <i class="iconfont icon-shanchu"></i>
              </template>
            </el-popconfirm>
          </el-tooltip> -->
        </template>
      </el-table-column>
    </el-table>

    <!-- 编辑科目弹窗 -->
    <el-dialog destroy-on-close  title="编辑科目" v-model="dialogVisibleEdit" width="25%">
      <el-form :model="editData" label-width="100px" label-position="right">
        <el-form-item  label="科目编码：">
          <el-input disabled style="width:200px" v-model="editData.code" size="small" ></el-input>
        </el-form-item>
        <el-form-item  label="科目名称：">
          <el-input style="width:200px" v-model="editData.name" size="small"></el-input>
        </el-form-item>
        <el-form-item  label="余额方向：">
          <el-radio v-model="editData.type" :label="1" size="small">借</el-radio>
          <el-radio v-model="editData.type" :label="2" size="small">贷</el-radio>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisibleEdit = false" size="small">取 消</el-button>
          <el-button type="primary" @click="handleEditSure" size="small">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 绑定辅助核算弹窗 -->
    <el-dialog destroy-on-close title="绑定辅助核算" v-model="dialogVisibleBind" width="30%">
      <el-form :model="editData" label-width="100px" label-position="right">
        <el-form-item  label="科目编码：">
          <el-input disabled style="width:200px" v-model="editData.code" size="small" ></el-input>
        </el-form-item>
        <el-form-item  label="科目名称：">
          <el-input disabled style="width:200px" v-model="editData.name" size="small"></el-input>
        </el-form-item>
        <el-form-item  label="余额方向：">
          <el-radio disabled v-model="editData.type" :label="1" size="small">借</el-radio>
          <el-radio disabled v-model="editData.type" :label="2" size="small">贷</el-radio>
        </el-form-item>
        <el-form-item label="核算方式：">
          <el-checkbox v-model="checked" label="辅助核算"></el-checkbox>
          <i class="iconfont icon-jiahao" @click="saProject" style="color: #23a5c3;font-size: 18px;padding-left: 10px;"></i>
        </el-form-item>
        <el-form-item label="辅助核算：" v-if="checked">
          <el-radio-group v-model="editData.fzhsId">
            <el-radio v-for="(item,index) in dataList" :key="index" :label="item.id">{{item.name}}</el-radio>
          </el-radio-group>
        </el-form-item>
        <!-- <el-form-item label="辅助核算项目名称：">
          <el-button type="primary" @click="saProject" size="small">添加</el-button>
        </el-form-item> -->
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button size="small" @click="dialogVisibleBind = false">取 消</el-button>
          <el-button size="small" type="primary" :loading="loading" @click="bingSubject">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 绑定外币弹窗 -->
    <el-dialog destroy-on-close title="绑定外币" v-model="dialogVisibleWbBind" width="30%">
      <el-form :model="wbData" label-width="100px" label-position="right">
        <el-form-item  label="科目编码：">
          <el-input disabled style="width:200px" v-model="wbData.code" size="small" ></el-input>
        </el-form-item>
        <el-form-item  label="科目名称：">
          <el-input disabled style="width:200px" v-model="wbData.name" size="small"></el-input>
        </el-form-item>
        <el-form-item label="币种：">
          <el-select v-model="wbData.wb" placeholder="请选择" size="small" style="width:200px">
            <el-option
              v-for="item in wbOptions"
              :key="item.id"
              :label="item.type"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="汇率制度：">
          <el-select v-model="rateValue" placeholder="请选择" size="small" @change="rateChange" style="width:200px">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="本期汇率：" v-if="rateValue == '固定汇率'">
          <el-input size="small" v-model="wbData.wbRate" style="width:200px"></el-input>
        </el-form-item>
        <el-button v-if="rateValue == '浮动汇率'" type="text" @click="dianji" size="small" style="margin-left:90px">点击查看人民币汇率中间价</el-button>
        <el-form-item>
          <el-checkbox v-model="remitChecked" label="期末调汇" @change="handleChange"></el-checkbox>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button size="small" @click="dialogVisibleWbBind = false">取 消</el-button>
          <el-button size="small" type="primary" :loading="loading" @click="bindWb">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <add-subject @success="getList()" :disabled="true" ref="addSubject"></add-subject>
    <!-- 补全科目弹窗 -->
    <bqkm ref="bqkm" @success="getList"></bqkm>
    <addProject ref="addProject" @success="fzhsList"></addProject>
    <qzf-import-back :hideButton="true" ref="importBck" @success="getList()" from="会计科目"></qzf-import-back>

  </div>
</template>

<script>
import { subjectTreeList , saveSubject , deleteEaSubject , fzhsList , subjectBindFzhs, subjectCancelBindFzhs ,subjectWbEdit , wbList } from "@/api/subject"
import bqkm from "./components/bqkm.vue"
import addProject from "./components/addProject"
export default {
  name:'subject',
  components:{ bqkm,addProject },
  data() {
    return{
      list: [],
      loading:false,
      listQuery:{
        id: 456,//456
        id2: 233,//233
      },
      types: [
        {
          name: "全部",
          value: ""
        },
        {
          name: "资产",
          value: '1'
        },
         {
          name: "负债",
          value: '2'
        },
         {
          name: "权益",
          value: '3'
        },
         {
          name: "成本",
          value: '4'
        },
         {
          name: "损益",
          value: '5'
        },
      ],   
      dialogVisibleEdit:false,
      dialogVisibleBind:false,
      dialogVisibleWbBind:false,
      editData:{},
      wbData:{
        remittance:0
      },
      lastSubject:'',
      ids: undefined,
      checked:false,
      itemChecked:false,
      dataList:[],
      checkList:[],
      options:[
        {
          value:'固定汇率',
          label:'固定汇率'
        },
        {
          value:'浮动汇率',
          label:'浮动汇率'
        }
      ],
      wbOptions:[],
      rateValue:'',
      remitChecked:false,
      code: ['1285','1403','1405','1406','1408','1411','1412','1421','1605'],
      setScrollTop:''
      // code: [],
    }
  },
  created(){
    this.contentStyleObj= this.$getHeight(160)
    this.getList()
  },
  mounted(){
    this.$refs.tableDate.$refs.bodyWrapper.addEventListener('scroll',(res) => {
      this.setScrollTop = res.target.scrollTop
    },true)
  },
  methods:{
    getList(val){
      this.loading = true
      this.listQuery.type2 = val
      // this.list = []
      if(this.list.length != 0){
        this.$refs.tableDate.setScrollTop(this.setScrollTop)
      }
      subjectTreeList(this.listQuery).then(res=>{
        this.list = res.data.data.list
        this.loading = false
        let newCode = this.list.filter((item)=>{
          return !item.code.indexOf("5",0)
        })
        newCode.map(v=>{
          this.code.push(v.code)
        })
      })
      // todo children里的还是会显示
    },
    //添加辅助核算项目名称
    saProject() {
      this.$refs.addProject.openDialog()
    },
    typeFilter(type){
      if(type == 1){
        return "借"
      }else if(type == 2){
        return "贷"
      }
    },
    add(row){
      let data = {
        type: row.type,
        id: row.id,
        name: '',
      }
      this.$refs.addSubject.init(data, [])
    },
    edit(row){
      this.dialogVisibleEdit = true
      this.editData.type = row.type
      this.editData.code = row.code
      this.editData.name = row.name
      this.editData.id = row.id
    },
    handleEditSure() {
      saveSubject(this.editData).then(res => {
        if (res.data.msg == "success") {
          this.dialogVisibleEdit = false;
          this.$qzfMessage("修改成功")
          this.getList()
          this.$store.dispatch("commons/getSubjects")
          this.$store.dispatch("commons/getBusinessType")
        }else {
          this.$message.error(res.data.msg);
        }
      });
    },
    deleteRow(id){
      this.$confirm('是否确认删除', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true
        deleteEaSubject([id]).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("删除成功")
            this.listQuery = {}
            this.getList()
            this.$store.dispatch("commons/getSubjects")
            this.$store.dispatch("commons/getBusinessType")
          }
        })
      })
    },
    handleSelectionChange(val) {
      this.ids = val.map(v=>{
        return {id: v.id}
      })
    },
    plDelete(){
      this.$confirm('是否确认删除', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true
        deleteEaSubject(this.ids).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("删除成功")
            this.listQuery = {}
            this.getList()
            this.$store.dispatch("commons/getSubjects")
            this.$store.dispatch("commons/getBusinessType")
          }
        })
      })
    },
    //补全科目
    comAccounts() {
      this.$refs.bqkm.init()
    },
    //辅助核算list
    fzhsList() {
      fzhsList({}).then(res=>{
        this.dataList = res.data.data.list
      })
    },
    // 绑定辅助核算
    binding(row){
      this.editData.type = row.type
      this.editData.code = row.code
      this.editData.name = row.name
      this.editData.fzhsId = row.fzhsId
      this.checked = Boolean(this.editData.fzhsId)
      this.editData.subjectId = row.id
      this.dialogVisibleBind = true
      fzhsList({}).then(res=>{
        this.dataList = res.data.data.list
      })
    },
    bingSubject(){
      //绑定
      if(this.checked && this.editData.fzhsId){
        this.loading = true
        subjectBindFzhs(this.editData).then(res=>{
          this.loading = false
          if(res.data.msg == "success"){
            this.$qzfMessage("绑定成功")
            this.$store.dispatch("commons/getSubjects")
            this.$store.dispatch("commons/getBusinessType")
            this.$bus.emit('fzhsSubjectListUpdate')
            this.$bus.emit('voucherUpdate')
            this.$bus.emit('invioceFeeUpdate')
            this.$bus.emit('invioceInUpdate')
            this.$bus.emit('invioceOutUpdate')
            this.$bus.emit('bankUpdate')
            this.$bus.emit('cashUpdate')
            this.$bus.emit('billUpdate')
            this.dialogVisibleBind = false
            this.getList()
          }
        })
      }else{
        this.loading = true
        subjectCancelBindFzhs(this.editData).then(res=>{
          this.loading = false
          if(res.data.msg == "success"){
            this.$qzfMessage("解绑成功")
            this.$store.dispatch("commons/getSubjects")
            this.$store.dispatch("commons/getBusinessType")
            this.$bus.emit('fzhsSubjectListUpdate')
            this.$bus.emit('voucherUpdate')
            this.$bus.emit('invioceFeeUpdate')
            this.$bus.emit('invioceInUpdate')
            this.$bus.emit('invioceOutUpdate')
            this.$bus.emit('bankUpdate')
            this.$bus.emit('cashUpdate')
            this.$bus.emit('billUpdate')
            this.dialogVisibleBind = false
            this.getList()
          }
        })
      }
    },
    // 绑定外币
    wbBinding(row){
      this.wbData = {
        remittance:0
      }
      this.wbData.code = row.code
      this.wbData.name = row.name
      this.wbData.id = row.id
      this.wbData.wb = row.wb
      this.wbData.wbRate = row.wbRate
      if(row.wbRate){
        this.rateValue = '固定汇率'
      }else{
        this.rateValue = '浮动汇率'
      }
      this.dialogVisibleWbBind = true
      wbList({}).then(res=>{
        this.wbOptions = res.data.data.list
      })
    },
    handleChange(){
      if(this.remitChecked == true){
        this.wbData.remittance = 1
      }else{
        this.wbData.remittance = 0
      }
    },
    bindWb(){
      this.loading = true
      subjectWbEdit(this.wbData).then(res=>{
        this.loading = false
        if(res.data.msg == "success"){
          this.$qzfMessage("绑定成功")
          this.$store.dispatch("commons/getSubjects")
          this.$store.dispatch("commons/getBusinessType")
          this.dialogVisibleBind = false
          this.getList()
        }
      })
    },
    rateChange(){
      if(this.rateValue == '浮动汇率'){
        this.wbData.wbRate = ''
      }
    },
    dianji(){
      window.open('https://www.chinamoney.com.cn/chinese/bkccpr/')
    },
    importSubject(){
      this.$refs.importBck.handImport()
    },
  }

}
</script>

<style lang="scss" scoped>
 
.top_right_btns {
  float: right;
  margin-right: 15px;
  i {
    margin: 0 5px;
    cursor: pointer;
    color: $color-main;
    font-size: 18px;
    line-height: 24px;
    // font-weight: 600;
  }
}
.el-select{
  margin: 0px;
}
.checkRate{
  cursor:pointer;
}
.checkRate:hover{
  color: $color-main;
}
</style>