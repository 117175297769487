<template>
  <el-dialog :close-on-click-modal="false" destroy-on-close title="补全科目" v-model="dialogVisibleBqSubject" width="40%"  class="button_bg">
    <div>
      <el-tree
        style="overflow:auto;height:550px;"
        ref="dictTree"
        :data="list"
        show-checkbox
        node-key="code"
        :default-expand-all="true"
        :default-checked-keys="selectKey"
        :props="defaultProps">
      </el-tree>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisibleBqSubject = false" size="small">取 消</el-button>
        <el-button type="primary" @click="handleAddSure" size="small" :loading="isLoading">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import {
  getCompleteSubjects,
  completeSubject
} from "@/api/build";
export default {
  props: {
    
  },
  data(){
    return{
      dialogVisibleBqSubject: false,
      defaultProps: {
        children: 'children',
        label: 'name3'
      },
      selectKey: [],
      openKey: [],
      title: "",
      list: [], 
      isLoading:false,
      contentStyleObj:{}, //高度变化

    }
  },
  created () {
    this.contentStyleObj= this.$getHeight(1060)
  },
  methods: {
    handleAddSure(){
      this.isLoading = true
      let currentKey = this.$refs.dictTree.getCheckedKeys()
      completeSubject({currentKey}).then(res=>{
        this.isLoading = false
        if(res.data.msg == "success"){
          // this.$store.dispatch("getSubjects")
          this.$message({
            message: '操作成功',
            type: 'success'
          });
          this.$emit("success")
          this.dialogVisibleBqSubject = false
        }
      })
    },
    ioc() {
      this.dialogVisibleBqSubject = true
    },
    init(){
      this.dialogVisibleBqSubject = true
      getCompleteSubjects({}).then(res=>{
        this.list = res.data.data.list
        this.open(this.list)
      })
    },
    open(list){
      list.map(v=>{
        this.selectKey.push(v.code)
        if(v.children){
          this.open(v.children)
        }
      })
    }
  }
};
</script>
<style lang="scss" scoped>

</style>